import React from 'react';
import moment from 'moment';
import RequestFormPage from "../request-form/request-form.page";
import {Container, Paper, Typography} from "@mui/material";

const InformationPage = () => {
    const startDate = moment('2025-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss')
    const endDate = moment('2025-01-05 23:59:59', 'YYYY-MM-DD HH:mm:ss')

    if (moment().isBetween(startDate, endDate, 'seconds')) {
        return (
            <Container component="main" maxWidth="sm" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                    <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                            Informasi
                        </Typography>
                        <Typography variant="subtitle1" align='justify'>
                            Mohon maaf untuk pengambilan produk sedang dihentikan sementara tanggal 1 - 5 Januari 2025
                            dikarenakan Warehouse dan Finance sedang melakukan proses stock opname.
                            Sistem dapat diakses kembali pada 6 Januari 2025 dan produk dapat diambil tanggal 9 Januari 2025. <br/>
                            Terima kasih.
                        </Typography>
                    </React.Fragment>
                </Paper>
            </Container>
        )
    } else {
        return (
            <RequestFormPage/>
        )
    }
}

export default InformationPage;